<template>
  <div class="index">
    <router-view></router-view>
    <!-- <flotCode></flotCode> -->
  </div>
</template>
<script>
import store from "@/store";
export default {
  name: 'index',
  data() {
    return {
      userInfo:{}
    };
  }
};
</script>
<style lang="scss">
  .el-header {
    padding: 0;
  }
  .el-aside {
    overflow-x: hidden;
  }
  .el-main {
    padding: 0 20px;
    height: calc(100vh - 50px);
    width: calc(100vh - 173px);
    min-width: 1200px;
    box-sizing: border-box;
    margin: 0;
  }
</style>
